@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

@font-face {
  font-family: "SpecialFont";
  src: url("./assets/fonts/specialalphabetsp04.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

// Reset CSS
* {
  padding: 0;
  margin: 0;
}

html,
body {
  font-family: Inter;
  font-size: 18px;
}

ol,
ul {
  list-style: none;
}

img {
  vertical-align: top;
  width: 100%;
  border: none;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  border: none;
  background: none;
  cursor: pointer;
}

input {
  border: none;
}

a {
  cursor: pointer;
}

.App {
  overflow: hidden;
  .page-header {
    .container {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 26px;
      background: #fff;
      padding: 20px;
      .logo {
        width: 50px;
        margin: 0 48px;
      }
      .header-pc {
        display: flex;
        @media only screen and (max-width: 786px) {
          display: none;
        }
        align-items: center;
        gap: 26px;
        flex-wrap: wrap;
        li {
          color: #000;
          text-align: center;
          font-family: Inter;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
      .header-mobile {
        display: none;
        @media only screen and (max-width: 786px) {
          display: flex;
          align-items: center;
          gap: 16px;
          flex-wrap: wrap;
          flex-direction: column;
          li {
            color: #000;
            text-align: center;
            font-family: Inter;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            padding: 12px 0;
          }
        }
      }
    }
  }
  .page-main {
    background: #000;
    display: flex;
    justify-content: center;
    .container {
      max-width: 80%;
      .section-intro {
        display: flex;
        gap: 156px;
        @media only screen and (max-width: 1179px) {
          flex-wrap: wrap;
          gap: 24px;
        }
        @media only screen and (max-width: 1179px) {
          justify-content: center;
        }
        .left-content {
          padding: 258px 0 262px;
          @media only screen and (max-width: 1179px) {
            display: flex;
            flex-direction: column;
            padding: 100px 0 100px;
            align-items: center;
            text-align: center;
          }
          .title {
            max-width: 326px;
            color: #fff;
            font-family: SpecialFont;
            font-size: 60px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-transform: uppercase;
          }
          .content {
            padding-top: 16px;
            color: #fff;
            font-family: Inter;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
        }
        .right-content {
          // background-image: url("./assets/images/bg-intro.png");
          // background-position: center;
          // background-repeat: no-repeat;
          // width: 583px;
          position: relative;
          img {
            position: relative;
            padding-top: 124px;
            @media only screen and (min-width: 1179px) {
              width: 583px;
            }
          }
        }
      }
      .section-about-us {
        display: flex;
        align-items: center;
        gap: 79px;
        @media only screen and (max-width: 1179px) {
          padding-top: 100px;
          flex-wrap: wrap;
          justify-content: center;
          text-align: center;
        }
        .left-content {
          width: 690px;
          @media only screen and (min-width: 1179px) {
            img {
              width: 690px;
            }
          }
        }
        .right-content {
          .title {
            color: #fff;
            font-family: SpecialFont;
            font-size: 48px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
          .content {
            padding-top: 32px;
            color: #fff;
            font-family: Inter;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
        }
      }
      .section-communication {
        padding-top: 163px;
        display: grid;
        grid-template-rows: auto auto;
        grid-template-columns: auto auto;
        grid-gap: 98px;
        justify-content: center;
        @media only screen and (max-width: 998px) {
          grid-template-columns: 1fr;
          grid-gap: 24px;
        }
        .item {
          border-radius: 10px;
          background: #fff;
          padding: 14px 140px;
          color: #000;
          text-align: center;
          font-family: SpecialFont;
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
      .section-join-us {
        padding-top: 138px;
        display: flex;
        flex-direction: column;
        align-items: center;
        @media only screen and (max-width: 768px) {
          padding-top: 100px;
        }
        .title {
          color: #fff;
          text-align: center;
          font-family: SpecialFont;
          font-size: 40px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
        .actions {
          margin-top: 20px;
          border-radius: 20px;
          background: #fff;
          display: inline-flex;
          padding: 27px 63px;
          @media only screen and (max-width: 768px) {
            padding: 17px 33px;
          }
          gap: 29px;
          .img-box {
            width: 80px;
            @media only screen and (max-width: 768px) {
              width: 60px;
            }
          }
        }
      }
    }
  }
  .page-footer {
    padding-top: 232px;
    background: #000;
    @media only screen and (max-width: 768px) {
      padding-top: 100px;
    }
    .container {
      border-top: 3px solid #fff;
      padding: 19px 28px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .logo-footer {
        width: 394px;
      }
      p {
        padding-top: 23px;
        color: #fff;
        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        @media only screen and (max-width: 768px) {
          text-align: center;
        }
      }
    }
  }
}

.bg-intro {

  position: absolute !important;
  
}